import React from 'react';
import { Typography } from '@material-ui/core';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

import SEO from '../components/SEO';
import Layout from '../layout/AppLayout';
import { ContentContainer } from '../styles/styled';

const eu = () => {
  return (
    <Layout>
      <SEO title="EU" />
      <ContentContainer>
        <Typography variant="h2" gutterBottom>
          <FormattedHTMLMessage id="euTitle" />
        </Typography>
        <Typography variant="h5" gutterBottom>
          <FormattedHTMLMessage id="eu1" />
        </Typography>
        <ul>
          <li>
            <Typography variant="body1" gutterBottom>
              <FormattedHTMLMessage id="euList1" />
            </Typography>
          </li>
          <li>
            <Typography variant="body1" gutterBottom>
              <FormattedHTMLMessage id="euList2" />
            </Typography>
          </li>
          <li>
            <Typography variant="body1" gutterBottom>
              <FormattedHTMLMessage id="euList3" />
            </Typography>
          </li>
        </ul>
      </ContentContainer>
    </Layout>
  );
};

export default eu;
